import React from 'react';
import Link from 'next/link';
import { TitleShadow } from '@/components/modules';
import { ImgResponsive } from '@/components/elements';
import { FaUpRightFromSquare } from 'react-icons/fa6';
import { StyleServiceItem } from './style';

type ServiceItemProps = {
  title?: string;
  prologue?: string;
  serviceImg?: string;
  serviceBg?: string;
  serviceUrl?: string;
  noLink?: boolean;
};

const ServiceItem = (props: ServiceItemProps) => {
  const {
    title = '',
    prologue = '',
    serviceImg = '',
    serviceBg = '',
    serviceUrl = '',
    noLink,
  } = props;

  return (
    <StyleServiceItem
      className="px-7 py-10 md:pt-14 lg:pt-24"
      serviceBg={`${serviceBg}`}
      {...props}
    >
      <div className="flex h-full flex-col">
        <div className="flex-none">
          <TitleShadow
            prologue={prologue}
            colorPrologue="rgba(0,0,0,.08)"
            title={title}
            colorTitle="#000000"
          />
        </div>
        <div className="grow">
          <a href={`/services/${serviceUrl}`}>
            <ImgResponsive
              src={serviceImg}
              alt={title}
              width={355}
              height={250}
              PicClassName="flex justify-center items-end h-full"
            />
          </a>
        </div>
        {noLink ? (
          <div className="mt-4 flex-none">&nbsp;</div>
        ) : (
          <div className="mt-4 flex-none">
            <Link
              className="service-readmore flex items-center gap-2"
              href={`/services/${serviceUrl}`}
            >
              อ่านเพิ่มเติม <FaUpRightFromSquare size={14} />
            </Link>
          </div>
        )}
      </div>
    </StyleServiceItem>
  );
};

export default ServiceItem;
